<template>
  <div class="app-container">
    <div class="page-title">
      <h2>L'unité de production</h2>
    </div>

    <!-- <el-card class="box-card"> -->
    <div class="filter-container">
      <!-- <el-button style="margin-left: auto" class="mr-15 filter-item" type="primary" icon="el-icon-plus" @click="PrintProductor">
        Imprimer
      </el-button> -->
    </div>
    <el-row>
      <el-col :span="7" style="margin-right:7px">
        <div class="grid-content bg-purple">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span style="font-weight:bold">Superviseur de l'Unité de Production</span>
              <!-- <el-button style="float: right; padding: 3px 0" type="text">Bouton</el-button> -->
            </div>
            <div v-if="data.controleur_responsable !== null" class="text item" style="display: flex; align-items: center; flex-direction:column">
              <!-- <div class="pan-thumb" />
              <span style="font-weight: bold; font-style: italic; margin-bottom: 5px">{{ data.nom + ' ' + data.prenom }}</span>
              <span style="font-style: italic;">{{ data.sexe ==='f'?'Féminin':'Masculin' | uppercaseFirst }}</span> -->
              <el-table v-loading="loading" :data="superInfos" :show-header="false">
                <el-table-column>
                  <template slot-scope="scope" style="background-color: bisque">
                    <span style="font-weight: bold;">{{ scope.row.libelle }}</span>
                  </template>
                </el-table-column>
                <el-table-column>
                  <template slot-scope="scope">
                    <span style="font-style: italic;">{{ scope.row.valeur }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-card>
          <div style="display: flex; align-items: center; flex-direction:column">
            <el-button style="margin-left: auto" class="mr-15 filter-item" type="primary" icon="el-icon-edit" @click="getSuperviseursList()">
              Changer le superviseur
            </el-button>
          </div>
        </div>
      </el-col>
      <el-col :span="16">
        <div class="grid-content bg-purple-light">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span style="font-weight: bold;">Autres Détails</span>
            </div>
            <el-tabs tab-position="top">
              <el-tab-pane label="Organisation">
                <el-table v-loading="loading" :data="persinfos" :show-header="false">
                  <el-table-column>
                    <template slot-scope="scope" style="background-color: bisque">
                      <span style="font-weight: bold;">{{ scope.row.libelle }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column>
                    <template slot-scope="scope">
                      <span style="font-style: italic;">{{ scope.row.valeur }}</span>
                    </template>
                  </el-table-column>
                </el-table>
              </el-tab-pane>
              <el-tab-pane label="Groupements">
                <el-table :data="data ? data.groupements : []" border fit highlight-current-row style="width: 100%">
                  <el-table-column align="center" label="Code" min-width="200px">
                    <template slot-scope="scope">
                      <span>{{ scope.row.code }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column align="center" label="Nom" min-width="150px">
                    <template slot-scope="scope">
                      <span>{{ scope.row.nom }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column align="center" label="Description" min-width="150px">
                    <template slot-scope="scope">
                      <span>{{ scope.row.description }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column align="center" :label="1==1 ? $t('general.actions') : $t('general.suppressionDefinitive')" width="200">
                    <template slot-scope="scope">
                      <el-button type="primary" size="small" icon="el-icon-view" style="margin-right:10px;" @click="navigateGroupement(scope.row.id)" />
                    </template>
                  </el-table-column>
                </el-table>
              </el-tab-pane>
              <el-tab-pane label="Controleurs">
                <el-table :data="data ? data.controleurs : []" border fit highlight-current-row style="width: 100%">
                  <el-table-column align="center" label="Code" min-width="200px">
                    <template slot-scope="scope">
                      <span>{{ scope.row.code }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column align="center" label="Nom" min-width="150px">
                    <template slot-scope="scope">
                      <span>{{ scope.row.nom }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column align="center" label="Description" min-width="150px">
                    <template slot-scope="scope">
                      <span>{{ scope.row.description }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column align="center" :label="1==1 ? $t('general.actions') : $t('general.suppressionDefinitive')" width="200">
                    <template slot-scope="scope">
                      <el-button type="primary" size="small" icon="el-icon-view" style="margin-right:10px;" @click="navigateControleur(scope.row.id)" />
                    </template>
                  </el-table-column>
                </el-table>
              </el-tab-pane>
            </el-tabs>
          </el-card>
        </div>
      </el-col>
    </el-row>
    <el-dialog
      title="Choisir le Superviseur"
      :visible.sync="superviseurAffectationDialog"
      :loading="affectingSuperviseur"
      width="30%"
    >
      <div class="content-inputs">
        <el-input placeholder="UP" :value="data.nom" disabled />
        <el-select v-model="selectedSuperviseur" style="margin-top:20px; width:100%" placeholder="Select">
          <el-option
            v-for="item in superviseurs"
            :key="item.id"
            :label="item.nom + ' ' + item.prenom"
            :value="item.id"
          />
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <div style="text-align:right;">
          <el-button plain type="danger" @click="superviseurAffectationDialog=false">
            {{ $t('permission.cancel') }}
          </el-button>
          <el-button type="primary" :loading="affectingSuperviseur" @click="affectSuperviseur()">
            Affecter
          </el-button>
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import Pagination from '@/components/Pagination'; // Pagination
import Resource from '@/api/resource';
import waves from '@/directive/waves'; // Directives
import permission from '@/directive/permission'; //  directive Permission
import role from '@/directive/role'; // Directives Role
import checkPermission from '@/utils/permission'; // Vérification Permission
import checkRole from '@/utils/role'; // Vérification Roles
import moment from 'moment';
const groupementResource = new Resource('groupements');
const uniteProductionResource = new Resource('unite_productions');
const villageResource = new Resource('villages');
const controleurResource = new Resource('controleurs');
const affectationSuperResource = new Resource('affecter_superviseur_unite_production');

export default {
  name: 'ProducteurInfo',
  // components: { Pagination },
  directives: { waves, permission, role },
  data() {
    return {
      data: null,
      date: '',
      fullscreenLoading: true,
      parcelles: null,
      printing: false,
      list: null,
      total: 0,
      sexe: '',
      image: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
      nomVillages: null,
      nomGroupements: null,
      query: {
        page: 1,
        limit: this.$limit,
        keyword: '',
      },
      loading: true,
      downloading: false,
      villages: null,
      groupements: null,
      activeList: 'disponibles',
      producteurCreating: false,
      editing: false,
      persinfos: null,
      superInfos: null,
      organInfos: null,
      currentProducteur: {},
      dialogCreateProducteur: false,
      currentProducteurId: 0,
      rules: {
        nom: [{ required: true, message: this.$t('producteur.NomRequired'), trigger: 'blur' }],
        prenom: [{ required: true, message: this.$t('producteur.PrenomRequired'), trigger: 'blur' }],
        numero: [{ required: true, message: this.$t('producteur.NumeroRequired'), trigger: 'blur' }],
        adresse: [{ required: true, message: this.$t('producteur.AdresseRequired'), trigger: 'blur' }],
      },
      fileList: [],
      mininfos: [],
      superviseurAffectationDialog: false,
      affectingSuperviseur: false,
      selectedSuperviseur: null,
      superviseurs: [],
    };
  },
  computed: {

  },
  created() {
    this.getGroupementsList();
    this.getVillagesList();
    this.getUnite();
  },
  methods: {
    checkPermission,
    checkRole,
    isEmptyArray(array){
      if (typeof array !== 'undefined' && array.length === 0) {
        return true;
      }
    },
    async getSuperviseursList() {
      if (this.superviseurs.length > 0) {
        this.superviseurAffectationDialog = true;
      } else {
        controleurResource.list(this.query)
          .then(async(response) => {
            /* response.data.forEach(controleur => {
              if (controleur.grade.nom === 'Superviseur') {
                this.superviseurs.push(controleur);
              }
            }); */
            this.superviseurs = response.data;
            console.log('Liste Sup:', this.superviseurs);
          })
          .catch(error => {
            console.log(error);
          });
        this.superviseurAffectationDialog = true;
      }
    },
    async affectSuperviseur(){
      this.affectingSuperviseur = true;
      this.loading = false;
      const data = {
        'controleur_id': this.selectedSuperviseur,
      };
      affectationSuperResource.extra(this.$route.params.id, data).then(response => {
        this.$message({
          type: 'success',
          message: 'Superviseur responsabilisé pour cette unité de production',
        });
        this.selectedSuperviseur = null;
        this.affectingSuperviseur = false;
        this.superviseurAffectationDialog = false;
        this.getGroupementsList();
        this.getUnite();
        this.getVillagesList();
        this.loading = false;
      }).catch(error => {
        console.log(error);
      });
    },
    async getGroupementsList() {
      const { data } = await groupementResource.list(this.query);
      this.groupements = data;
      var _reg = {};
      data.forEach(groupement => {
        _reg[groupement.id] = groupement.nom;
      });
      this.nomGroupements = _reg;
    },
    PrintProductor(){
      var routeData = this.$router.resolve({ path: '/producteur/' + this.$route.params.id });
      window.open(routeData.href, '_blank');
    },
    navigateImpression(){
      var routeData = this.$router.resolve({ path: '/parcelle/' + this.$route.params.id });
      window.open(routeData.href, '_blank');
    },
    navigateGroupement(id){
      this.$router.push({ path: '/groupement/' + id });
    },
    navigateControleur(id){
      this.$router.push({ path: '/controleur/' + id });
    },
    async getVillagesList() {
      const { data } = await villageResource.list(this.query);
      this.villages = data;
      var _reg = {};
      data.forEach(village => {
        _reg[village.id] = village.nom;
      });
      this.nomVillages = _reg;
    },
    async getUnite() {
      // const { data } = await producteursResource.get(this.$route.params.id);
      const { data } = await uniteProductionResource.get(this.$route.params.id);
      this.data = data;
      console.log('leproduitttttttttt : ', this.data);
      this.fullscreenLoading = false;
      console.log('le loading : ', this.fullscreenLoading);
      this.date = moment().format('DD-MM-YYYY');
      // this.organInfos = [
      //   {
      //     'libelle': 'Code UP    :',
      //     'valeur': data.unite_production !== null ? data.unite_production.nom : 'INDISPONIBLE',
      //   },
      //   {
      //     'libelle': 'Zone    :',
      //     'valeur': data.zone !== null ? data.zone.nom : 'INDISPONIBLE',
      //   },
      //   {
      //     'libelle': 'Commune    :',
      //     'valeur': data.commune !== null ? data.commune.nom : 'INDISPONIBLE',
      //   },
      //   {
      //     'libelle': 'Groupement',
      //     'valeur': data.groupement !== null ? data.groupement.nom : 'INDISPONIBLE',
      //   },
      //   {
      //     'libelle': 'Village',
      //     'valeur': data.village !== null ? data.village.nom : 'INDISPONIBLE',
      //   },
      // ];
      this.persinfos = [
        {
          'libelle': 'Nom    :',
          'valeur': data.nom,
        },
        {
          'libelle': 'Description    :',
          'valeur': data.description,
        },
      ];

      this.superInfos = [
        {
          'libelle': 'Nom Prénoms :',
          'valeur': data.controleur_responsable ? data.controleur_responsable.nom + ' ' + data.controleur_responsable.prenom : 'AUCUN',
        },
        {
          'libelle': 'Numéro    :',
          'valeur': data.controleur_responsable ? data.controleur_responsable.numero : 'AUCUN',
        },
      ];
      // this.mininfos = [
      //   {
      //     'name1': 'Code UP',
      //     'val1': data.unite_production !== null ? data.unite_production.nom : 'INDISPONIBLE',
      //     'name2': 'Nom du producteur',
      //     'val2': data.nom + ' ' + data.prenom,
      //   },
      //   {
      //     'name1': 'Zone',
      //     'val1': data.zone !== null ? data.zone.nom : 'INDISPONIBLE',
      //     'name2': 'Sexe (M/F)',
      //     'val2': data.sexe,
      //   },
      //   {
      //     'name1': 'Commune',
      //     'val1': data.commune !== null ? data.commune.nom : 'INDISPONIBLE',
      //     'name2': 'Groupement',
      //     'val2': data.groupement !== null ? data.groupement.nom : 'INDISPONIBLE',
      //   },
      //   {
      //     'name1': 'Village',
      //     'val1': data.village !== null ? data.village.nom : 'INDISPONIBLE',
      //     'name2': 'Code Producteur',
      //     'val2': data.code,
      //   },
      // ];
      this.loading = false;
      // console.log('tableau : ', this.mininfos);
      // if (data !== null) {
      //   window.print();
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-input {
  padding-right: 100px;
}
.cancel-btn {
  position: absolute;
  right: 15px;
  top: 10px;
}
.dialog-footer {
  text-align: left;
  padding-top: 0;
  margin-left: 150px;
}
.app-container {
  flex: 1;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  .block {
    float: left;
    min-width: 250px;
  }
  .clear-left {
    clear: left;
  }
}

.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}

.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.pan-thumb {
  width: 150px;
  height: 150px;
  background-position: center center;
  background-size: cover;
  border-radius: 75px;
  overflow: hidden;
  margin-bottom: 20px;
  // position: absolute;
  background-image:url('https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg');
  // transform-origin: 95px 40px;
  // transition: all 0.3s ease-in-out;
  background-repeat:no-repeat;
}
</style>
